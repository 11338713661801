<template>
  <p
    v-html="visibleText"
    class="pre_wrap_spire text-block"
    :class="{
      active: expanded,
      'expandable-text': isExpandable,
    }"
  ></p>
  <spire-button
    v-if="isExpandable"
    @click="toggleExpanded"
    variant="tertiary"
    class="read-more-link no-underline"
    :class="{ open: expanded }"
    :title="expanded ? 'Read less' : 'Read the rest'"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

interface Props {
  text: string;
  maxChars?: number;
}

const props = withDefaults(defineProps<Props>(), {
  maxChars: 300,
});

const expanded = ref(false);

const isExpandable = computed(() => props.text.length > props.maxChars);

const visibleText = computed(() => {
  if (isExpandable.value && !expanded.value) {
    return props.text.slice(0, props.maxChars) + "…";
  } else {
    return props.text;
  }
});

const toggleExpanded = () => {
  expanded.value = !expanded.value;
};
</script>

import { createInput } from "@formkit/vue";
import { generateClasses } from "@formkit/themes";
import FormKitDatePicker from "@/programs/components/inputs/FormKitDatePicker.vue";
import FormKitPreferenceExerciseOptions from "@/programs/views/student_preference_exercise/components/FormKitPreferenceExerciseOptionList.vue";
import CourseMembershipComboBox from "@/programs/components/inputs/CourseMembershipComboBox.vue";
import FormKitMultiSelect from "@/programs/components/inputs/FormKitMultiSelect.vue";
import RichTextEditor from "@/programs/components/inputs/RichTextEditor.vue";
import SingleFileInput from "@/programs/components/inputs/FormKitSingleFileInput.vue";
import CompetencySelect from "@/programs/components/inputs/CompetencySelect.vue";
import LevelSelect from "@/programs/components/inputs/LevelSelect.vue";
import ExternalItemReferenceSelect from "@/programs/components/inputs/ExternalItemReferenceSelect.vue";
import {
  isEmbedUrl,
  isFutureDateTime,
  isValidUrl,
  isYouTubeUrl,
  isBeforeEndDate,
} from "@/programs/utils/customValidationRules";

const isRequired = (node) =>
  node.props.parsedRules.some((rule) => rule.name === "required");

const isCheckboxAndRadioMultiple = (node) =>
  (node.props.type === "checkbox" || node.props.type === "radio") &&
  node.props.options;

const rootClasses = (section, node) => {
  const isMultiOption = isCheckboxAndRadioMultiple(node);
  const required =
    (isMultiOption ? section === "legend" : section === "label") &&
    isRequired(node);
  return {
    [`formkit-${section}`]: true,
    required,
  };
};

export default {
  inputs: {
    FormKitDatePicker: createInput(FormKitDatePicker, {
      props: ["inline", "mode"],
    }),
    PreferenceExerciseOptions: createInput(FormKitPreferenceExerciseOptions, {
      props: [
        "showDimensions",
        "options",
        "uncategorizedOptions",
        "categories",
      ],
    }),
    CourseMembershipComboBox: createInput(CourseMembershipComboBox),
    FormKitMultiSelect: createInput(FormKitMultiSelect, {
      props: [
        "multiple",
        "closeOnSelect",
        "searchable",
        "loading",
        "placeholder",
        "allowEmpty",
        "groupLabel",
        "groupValues",
        "groupSelect",
      ],
    }),
    FormKitRichTextEditor: createInput(RichTextEditor, {
      props: [
        "editorClass",
        "allowVideos",
        "allowLinks",
        "allowLists",
        "allowHeaders",
        "allowImages",
      ],
    }),
    SingleFileInput: createInput(SingleFileInput, {
      props: ["original"],
    }),
    CompetencySelect: createInput(CompetencySelect, {
      props: [
        "programId",
        "multiple",
        "closeOnSelect",
        "searchable",
        "placeholder",
        "allowEmpty",
      ],
    }),
    LevelSelect: createInput(LevelSelect, {
      props: [
        "programId",
        "multiple",
        "closeOnSelect",
        "searchable",
        "placeholder",
        "allowEmpty",
        "levelType",
      ],
    }),
    ExternalItemReferenceSelect: createInput(ExternalItemReferenceSelect, {
      props: [
        "programId",
        "multiple",
        "closeOnSelect",
        "searchable",
        "placeholder",
        "allowEmpty",
        "includedReferenceTypes",
      ],
    }),
  },
  config: {
    rootClasses,
    classes: generateClasses({
      global: {
        label: "spire-label",
      },
      FormKitRichTextEditor: {
        inner: "$remove:formkit-inner",
      },
      FormKitDatePicker: {
        wrapper: "direct-calendar",
      },
    }),
  },
  rules: {
    isEmbedUrl,
    isFutureDateTime,
    isValidUrl,
    isYouTubeUrl,
    isBeforeEndDate,
  },
  messages: {
    en: {
      validation: {
        isValidUrl() {
          return "Please enter a valid url (beginning with http:// or https://)";
        },
        isBeforeEndDate() {
          return "Start date must come before end date.";
        },
      },
    },
  },
};

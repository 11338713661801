<script setup>
import { computed, ref, onMounted } from "vue";
import VueMultiselect from "vue-multiselect";
import { useVMSFormKitShim } from "@/programs/composables/useVMSFormKitShim.js";
import { getExternalItemReferenceOptions } from "@/programs/services/ExternalItemReferenceService.ts";

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const multiple = props.context.multiple;
const closeOnSelect = props.context.closeOnSelect || multiple === false;
const searchable = props.context.searchable;
const allowEmpty = props.context.allowEmpty;
const includedReferenceTypes = props.context.includedReferenceTypes;

const options = ref([]);
const loading = ref(false);
const error = ref(false);

const placeholder = computed(() => {
  if (error.value === true) {
    return "Failed to load references";
  }
  return props.context.placeholder || "Select external reference";
});

const filteredOptions = computed(() => {
  return includedReferenceTypes
    ? options.value.filter((referenceOption) =>
        includedReferenceTypes.includes(referenceOption.referenceType),
      )
    : options.value;
});

const { value } = useVMSFormKitShim(props.context, options, {
  replaceMissingValue: () => null,
});

onMounted(async () => {
  error.value = false;
  loading.value = true;
  try {
    const response = await getExternalItemReferenceOptions({
      programId: props.context.programId,
    });
    options.value = response.data;
  } catch (err) {
    error.value = true;
    throw err;
  } finally {
    loading.value = false;
  }
});
</script>
<template>
  <vue-multiselect
    v-model="value"
    :show-labels="true"
    :multiple="multiple"
    :searchable="searchable"
    :close-on-select="closeOnSelect"
    :options="filteredOptions"
    :disabled="context.disabled"
    :loading="loading"
    :name="context.node.name"
    :id="context.id"
    :placeholder="placeholder"
    :allow-empty="allowEmpty"
    label="label"
    track-by="value"
    open-direction="auto"
    v-bind="$attrs"
    class="vue-multiselect"
  >
  </vue-multiselect>
</template>

import { EXTERNAL_ITEM_REFERENCE_API_V2_URLS } from "@/programs/urls";
import {
  restfulShowPaginated,
  restfulCreate,
  restfulShow,
} from "@/programs/services/ServiceHelpers.ts";

export enum ExternalReferenceSource {
  ECoach = "ecoach",
}

export enum ExternalReferenceType {
  CourseGrade = "course_grade",
  AssignmentGrade = "assignment_grade",
  CourseRoster = "course_roster",
}

export interface ExternalItemReference {
  id: number;
  referenceSource: ExternalReferenceSource;
  referenceType: ExternalReferenceType;
  referenceValues: {
    termCode?: string;
    courseCode?: string;
    assignmentId?: string;
  };
}

export type ExternalItemReferenceCreatePayload = Omit<
  ExternalItemReference,
  "id"
>;

export interface ExternalItemReferenceOption {
  id: number;
  label: string;
  referenceType: ExternalReferenceType;
}

interface CollectionURLParams {
  programId: number;
}

export const getExternalItemReferences = restfulShowPaginated<
  CollectionURLParams,
  ExternalItemReference[]
>(EXTERNAL_ITEM_REFERENCE_API_V2_URLS.index);

export const createExternalItemReference = restfulCreate<
  CollectionURLParams,
  ExternalItemReference,
  ExternalItemReferenceCreatePayload
>(EXTERNAL_ITEM_REFERENCE_API_V2_URLS.index);

export const getExternalItemReferenceOptions = restfulShow<
  CollectionURLParams,
  ExternalItemReferenceOption[]
>(EXTERNAL_ITEM_REFERENCE_API_V2_URLS.options);

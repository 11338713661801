<template>
  <li>
    <spire-tag
      @click.prevent="deleteOption"
      :aria-label="`Delete ${option.label}`"
      :is-removable="false"
      variant="removable"
      icon-right="fa-close"
      class="spire-tag--wrap"
      :title="option.label"
    />
  </li>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  emits: ["delete-option"],
  methods: {
    deleteOption() {
      this.$emit("delete-option", this.option);
    },
  },
};
</script>

<template>
  <spire-button
    @click="show = true"
    :title="triggerText"
    :variant="triggerVariant"
    :sizing="triggerSizing"
    :auto-responsive
    :disabled
    :icon-left="triggerIconLeft"
    :icon-right="triggerIconRight"
    ref="trigger"
    :aria-label="triggerAriaLabel"
    aria-haspopup="true"
    :aria-expanded="showStatusString"
  />
  <slot name="modal" v-if="show" :close-modal="closeModal">
    <spire-modal @close-modal="closeModal">
      <template #header>
        <slot name="header" :close-modal="closeModal"></slot>
      </template>
      <template #content>
        <slot name="content" :close-modal="closeModal"></slot>
      </template>
      <template #footer v-if="$slots.footer">
        <slot name="footer" :close-modal="closeModal"></slot>
      </template>
    </spire-modal>
  </slot>
</template>

<script>
import { isEmpty } from "lodash";
export default {
  props: {
    triggerText: {
      type: String,
      required: true,
    },
    triggerSizing: {
      type: String,
      default: "normal",
    },
    triggerVariant: {
      type: String,
      default: "primary",
    },
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    triggerIconLeft: {
      type: String,
      default: "",
    },
    triggerIconRight: {
      type: String,
      default: "",
    },
    autoResponsive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: "",
    },
  },
  emits: ["close-modal"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      this.$emit("close-modal");
    },
    showModal() {
      this.show = true;
    },
  },
  computed: {
    showStatusString() {
      return this.show ? "true" : "false";
    },
    triggerAriaLabel() {
      return isEmpty(this.ariaLabel) ? this.triggerText : this.ariaLabel;
    },
  },
  watch: {
    show(newValue) {
      const closing = !newValue;
      if (closing) {
        this.$refs.trigger.$el.focus();
      }
    },
  },
};
</script>
